import React from "react";
import image from "../media/bg.jpg";

function Home() {
  return (
      <main className="min-h-screen">
        {/* <img
          src={image}
          alt="Monstera albo leaves"
          className="bg_image absolute object-cover w-full h-full"
        /> */}
        <section className="typewriter relative flex justify-center pt-12 lg:pt-64 px-8">
          <h1 className="text-green-800 font-extrabold cursive leading-none">
            This is U's Code Garden
          </h1>
        </section>
      </main>
   );
}

export default Home;
