import React from "react";
import { NavLink } from "react-router-dom";
import Hello from "./Hello.js";
import { Navbar, Nav } from "react-bootstrap";

export default function Header() {
  return (
    <Navbar expand="lg">
      <NavLink
        to="/"
        className="inflex-flex items-center py-6 px-3 mr-4 text-slate-700 hover:text-green-700 text-4xl font-bold cursive tracking-widest no-underline"
      >
        <Hello />
      </NavLink>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto">
          {/* <NavLink
            to="/post"
            className="hover-underline-animation inline-flex items-center py-3 px-3 text-slate-700 hover:text-green-700 text-xl no-underline tracking-widest cursive"
          >
            Blog Posts
          </NavLink> */}
          <NavLink
            to="/project"
            className="hover-underline-animation inline-flex items-center py-3 px-3 text-slate-700 hover:text-green-700 text-xl no-underline tracking-widest cursive"
          >
            Projects
          </NavLink>
          <NavLink
            to="/about"
            className="hover-underline-animation inline-flex items-center py-3 px-3 text-slate-700 hover:text-green-700 text-xl no-underline tracking-widest cursive"
          >
            About Me!
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
