import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Home from "./components/Home";
import About from "./components/About";
import Project from "./components/Project";
import Header from "./components/Header";
import Footer from './components/Footer';
import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route exact element={<Home/>} path="/" />
      <Route element={<About/>} path='/about' />
      {/* <Route element={<SinglePost/>} path='/post/:slug' />
      <Route element={<Post/>} path='/post' /> */}
      <Route element={<Project/>} path='/project' />
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;
