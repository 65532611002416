import React from "react";
import self from "../media/self.png";
import library from "../media/library.jpg";
import radio from "../media/radio.png";
import AutoPlayCarousel from "./AutoPlayCarousel";

function About() {
  return (
    <main className="about_page">
      <article>
        <h3 className="text-3xl">INTRO</h3>
        <p className="py-2 text-xl">
          I work as a software engineer, turning idea into validated working
          products and services through web accessibility and efficient code
          structure. I enjoy that peaceful, leafy life~~
        </p>
        <br />
        <div
          className="intro_self"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            alt="myself"
            src={self}
            style={{ maxWidth: "80%", borderRadius: "8px" }}
          />
        </div>
      </article>
      <br />
      <h3 className="text-3xl py-4">SELECTED WORK</h3>
      <div className="intro_flex" style={{ display: "flex" }}>
        <div className="intro_column px-2">
          <img
            alt="library_project"
            src={library}
            style={{ borderRadius: "8px" }}
          />
          <p className="text-xl w-full">
            Campus Library's interactive schedule App
          </p>
          <span style={{ display: "flex", justifyContent: "start" }}>
            <img
              src="https://img.icons8.com/dusk/64/000000/php-logo.png" alt="php icon"
              style={{ width: "30px", height: "30px" }} 
            />
            <img
              src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/000000/external-jquery-is-a-javascript-library-designed-to-simplify-html-logo-color-tal-revivo.png"
              alt ="jquery icon"
              style={{ width: "30px", height: "30px", marginLeft: "0.5rem" }}
            />
            <img
              src="https://img.icons8.com/color/48/000000/drupal.png"
              alt ="drupal icon"
              style={{ width: "30px", height: "30px", marginLeft: "0.5rem" }}
            />
          </span>
        </div>

        <div className="intro_column px-2">
          <img
            alt="radio_project"
            src={radio}
            style={{ borderRadius: "8px" }}
          />
          <p className="text-xl w-full">
            Campus Radio Station's full stack project
          </p>
          <span style={{ display: "flex", justifyContent: "start" }}>
            <img
              src="https://img.icons8.com/color/48/000000/mysql-logo.png"
              alt ="mysql icon"
              style={{ width: "30px", height: "30px" }}
            />
            <img
              src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/24/000000/external-react-a-javascript-library-for-building-user-interfaces-logo-shadow-tal-revivo.png"
              alt ="react icon"
              style={{ width: "30px", height: "30px", marginLeft: "0.5rem" }}
            />
            <img
              src="https://img.icons8.com/ios-filled/50/000000/django.png"
              alt ="django icon"
              style={{ width: "30px", height: "30px", marginLeft: "0.5rem" }}
            />
          </span>
        </div>
      </div>
      <br />
      <h3 className="text-3xl py-4">LEAFY LIFE</h3>
      <AutoPlayCarousel />
    </main>
  );
}

export default About;
