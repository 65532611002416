import React, { useState, useEffect } from "react";

function Hello() {
  const [lang, setLang] = useState("");
  const langArr = [
    "你好",
    "こんにちは",
    "안녕하세요",
    "Bonjour",
    "Hola",
    "Hallo",
    "Ciao",
    "नमस्ते",
    "Hej",
    "Hello",
  ];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setLang(() => langArr[index]);
      index = (index + 1) % langArr.length;
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
    {(lang=== null || lang === "") ? <span>Hello</span> : <span>{lang}</span>} <span>I'm U.</span>
    </>
  );
}

export default Hello;
