const Footer = () => {
  return (
    <footer style={{width:"100%", position: "fixed", bottom: 0, backgroundColor: "white"}}>
      <p style={{ display: "flex", justifyContent: "center" }} className="text-sm">
        &copy; 2022 - {new Date().getFullYear()} Copyright:{" "}
        <a href="https://uuyhe.com"> uuyhe.com </a>{" "}
      </p>
    </footer>
  );
};

export default Footer;
