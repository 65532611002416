import React from "react";
import styled from "styled-components";
import ItemsCarousel from "react-items-carousel";
import plant0 from "../media/plant0.jpg";
import plant1 from "../media/plant1.jpg";
import plant2 from "../media/plant2.jpg";
import plant3 from "../media/plant3.jpg";
import plant4 from "../media/plant4.jpg";
import plant5 from "../media/plant5.jpg";
import plant6 from "../media/plant6.jpg";

const noOfItems = 7;
let noOfCards = 3;
const autoPlayDelay = 2000;
const chevronWidth = 40;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 100%;
  margin: 0;
`;

const carouselObject = [
  {
    url: plant0,
    alt: "rubber",
  },
  {
    url: plant1,
    alt: "mican",
  },
  {
    url: plant2,
    alt: "syngonium albo",
  },
  {
    url: plant3,
    alt: "alocasia black velvet",
  },
  {
    url: plant4,
    alt: "monstera albo",
  },
  {
    url: plant5,
    alt: "florida ghost mint",
  },
  {
    url: plant6,
    alt: "string of heart",
  },
];

export default class AutoPlayCarousel extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDesktop: false, activeItemIndex: 0 };
    this.updateWindowSize = this.updateWindowSize.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateWindowSize);
    clearInterval(this.interval);
    
  }

  updateWindowSize() {
    this.setState({ isDesktop: window.innerWidth > 800 });
  }

  tick = () =>
    this.setState((prevState) => ({
      activeItemIndex:
        (prevState.activeItemIndex + 1) % (noOfItems - noOfCards + 1),
    }));

  onChange = (value) => this.setState({ activeItemIndex: value });

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <>
        {isDesktop ? (
          <Wrapper>
            <ItemsCarousel
              gutter={12}
              numberOfCards={noOfCards}
              activeItemIndex={this.state.activeItemIndex}
              requestToChangeActive={this.onChange}
              rightChevron={<button>{">"}</button>}
              leftChevron={<button>{"<"}</button>}
              chevronWidth={chevronWidth}
              outsideChevron
            >
              {Array.from(new Array(7)).map((_, i) => (
                <img
                  className="carousel_images"
                  key={i}
                  src={carouselObject[i].url}
                  alt={carouselObject[i].alt}
                />
              ))}
            </ItemsCarousel>
          </Wrapper>
        ) : (
          <Wrapper>
            <ItemsCarousel
              gutter={12}
              numberOfCards={1}
              activeItemIndex={this.state.activeItemIndex}
              requestToChangeActive={this.onChange}
              rightChevron={<button>{">"}</button>}
              leftChevron={<button>{"<"}</button>}
              chevronWidth={chevronWidth}
              outsideChevron
            >
              {Array.from(new Array(7)).map((_, i) => (
                <img
                  className="carousel_images"
                  key={i}
                  src={carouselObject[i].url}
                  alt={carouselObject[i].alt}
                />
              ))}
            </ItemsCarousel>
          </Wrapper>
        )}
      </>
    );
  }
}
